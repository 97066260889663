import AtgForgotPassword from 'composable/components/account/atg-forgot-password'
import AtgLoginPage from 'composable/components/account/atg-login-page'
import React, { useEffect, useState } from 'react'
import SignUpModal from '../SignupModal'

const SignInSignUpGlobal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState(false)

  const toggleForgotPassword = () => {
    setShowForgotPasswordModal(!showForgotPasswordModal)
    if (isModalOpen) {
      closeModal()
    }
  }

  const openLoginModal = () => {
    setIsModalOpen(true)
  }
  const openSignUpModal = () => {
    setIsOpenSignUpModal(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setIsOpenSignUpModal(false)
  }

  useEffect(() => {
    const openSignInorSignUpModal = (event) => {
      const target = event.target
      const href = target?.href
      if (href && (href?.indexOf('#signup') > -1 || href?.indexOf('#signin') > -1)) {
        event.preventDefault()
        if (href.indexOf('#signup') > -1) {
          openSignUpModal()
        }
        if (href.indexOf('#signin') > -1) {
          openLoginModal()
        }
      }
    }

    document.addEventListener('click', openSignInorSignUpModal, true)

    return () => {
      document.removeEventListener('click', openSignInorSignUpModal)
    }
  }, [])

  return (
    <>
      <AtgLoginPage isOpen={isModalOpen} onClose={closeModal} toggleForgotPassword={toggleForgotPassword} />
      <AtgForgotPassword isOpen={showForgotPasswordModal} onClose={toggleForgotPassword} />
      <SignUpModal isOpen={isOpenSignUpModal} onClose={closeModal} openSignInModal={openLoginModal} />
    </>
  )
}

export default SignInSignUpGlobal
